<template>
  <div class="branches-page">
    <div class="branches-page-form">
        <div class="branches-page-form-field">
            <h2>בחירת סניף</h2>
            <el-select style="width:100%;" filterable v-model="data.selected_branche" class="m-2" placeholder="בחירת סניף" size="large">
                <el-option
                v-for="branche in branches"
                :key="branche"
                :value="branche" 
                />
            </el-select>
        </div>
        <div class="branches-page-form-field">
            <h2>שם סופר המלאי</h2>
            <el-input v-model="data.counter_name" placeholder="שם סופר המלאי" />
        </div>
        <div class="branches-page-form-field">
            <el-button @click="handle_submit" style="width:100%;" type="primary">המשך</el-button>
        </div>
    </div>
  </div>
</template>

<script>
import { ref } from '@vue/reactivity'
import {slide_pop_error} from '../../../../../Methods/Msgs'
import { onActivated } from 'vue'
export default {
    props:['branches','after_exit'],
    emits:['go_to_items_page','before_item_exist'],
    setup(props,{emit}){
        
        const err_msg = ref('')

        const data = ref({
            selected_branche:'',
            counter_name:'',
            branche_num:'',
        })

        const validation = () => {
            for (const [key, value] of Object.entries(data.value)) {
                switch (key) {
                    case 'selected_branche':
                        if(!value){
                            err_msg.value = 'עליך לבחור סניף!'
                            return false
                        }
                        break;
                    case 'counter_name':
                        if(!value){
                            err_msg.value = 'עליך להזין את שם סופר המלאי!'
                            return false
                        }
                        break;
                }
            }
            return true
        }

        const handle_submit = () => {
            if(!validation()){
                slide_pop_error(err_msg.value)
            }else{
                data.value.branche_num = justNumbers(data.value.selected_branche)
                emit('go_to_items_page',data.value)
            }
        }

        function justNumbers(string) {
            var numsStr = string.replace(/[^0-9]/g, '');
            return parseInt(numsStr);
        }

        onActivated(()=>{
            console.log('im here');
            if(props.after_exit){
                console.log('after_exist');
                data.value = {
                    selected_branche:'',
                    counter_name:'',
                    branche_num:'',
                }
                emit('before_item_exist',false)
            }
        })



        return{
            data,
            handle_submit
        }
    }
}
</script>

<style scoped>
    .branches-page{
        width: 100%;
        height: 100%;
        /* display: flex;
        justify-content: center;
        align-items: center; */
    }
    .branches-page-form{
        width: 100%;
        max-width: 500px;
        height: fit-content;
        margin: 50px auto;
    }
    .branches-page-form-field{
        width: 100%;
        height: fit-content;
        margin-bottom: 5px;
    }
</style>