import { projectFirestore } from "../../../../../firebase/config"

import {counted_selected} from '../store'

const save_count_stock_in_db = async(data) => {
    const doc_ref = projectFirestore.collection('Stock_Data').doc(String(data.branche_num));
    let doc = await doc_ref.get()
    if(!doc.exists){
        await projectFirestore.collection('Stock_Data').doc(String(data.branche_num))
        .set({
            branche_name:data.selected_branche,
            branche_num:data.branche_num,
        })
    }

    
    doc = doc_ref.collection('Counts').doc()
    await doc.set({
        counter_name:data.counter_name,
        date:data.date,
        ...data.selected_item,
        count_uid:doc.id
    })
    counted_selected.value.push({
        counter_name:data.counter_name,
        date:data.date,
        ...data.selected_item,
        count_uid:doc.id
    })

}

const update_count_stock_in_db = async(data,item) => {
    await projectFirestore.collection('Stock_Data').doc(String(data.branche_num))
    .collection('Counts').doc(item.count_uid).set(item)
}

const delete_count_stock_in_db = async(data,item) => {
    console.log('num:',data.branche_num);
    console.log(item.count_uid);
    console.log(item);
    await projectFirestore.collection('Stock_Data').doc(String(data.branche_num))
    .collection('Counts').doc(item.count_uid).delete()
}

export{
    save_count_stock_in_db,
    update_count_stock_in_db,
    delete_count_stock_in_db
}