<template>
  <div class="stock-client-main">
    <div class="stock-client-main-wrapper">
        <div class="stock-client-main-wrapper-header">
            <h2>ספירות מלאי</h2>
        </div>
        <div class="stock-client-main-wrapper-content">
            <keep-alive>
                <component 
                    :items="items" 
                    :branches="branches" 
                    :is="state"
                    :reset_item_page="reset_item_page"
                    :after_exit="after_exit"
                    @before_item_exist="after_exit = false"
                    @stop_reset="reset_item_page=false"
                    @go_to_items_page="handle_go_to_item_page"
                    @go_to_branches_page="state='BranchesPage'"
                    @submit="handle_submit"
                    @submit_update="handle_submit_update"
                    @submit_delete="handle_submit_delete"
                    @back_to_start="handle_back_to_start"
                />
            </keep-alive>
        </div>
    </div>
  </div>
</template>

<script>
import { ref } from '@vue/reactivity'
import {get_branches_from_db} from '../../../../Methods/rosman_shivuk_funcs'
import BranchesPage from './components/BranchesPage.vue'
import {get_data_from_db} from '../Admin/scripts/scripts';
import { defineAsyncComponent, onBeforeUnmount } from '@vue/runtime-core';
import {save_count_stock_in_db,update_count_stock_in_db,delete_count_stock_in_db} from './scripts/scripts';
import {counted_selected} from './store'
import {alert} from '../../../../Methods/Msgs'
export default {
    components:{
        BranchesPage,
        ItemsPage:defineAsyncComponent(()=>import('../client/components/ItemsPage.vue'))
    },
    setup(){
        
        const after_exit = ref(false)

        const reset_item_page = ref(false)

        const is_pending = ref(false)
        const state = ref('BranchesPage')

        const branches = ref([])
        const items  = ref([])

        const data = ref({
            selected_branche:'',
            branche_num:'',
            counter_name:'',
            selected_item:null,
            date: new Date().toLocaleDateString('he')
        })

        const handle_back_to_start = () => {
            after_exit.value = true
            data.value = {
                selected_branche:'',
                branche_num:'',
                counter_name:'',
                selected_item:null,
                date: new Date().toLocaleDateString('he')
            }
            state.value = 'BranchesPage'
        }

        const handle_go_to_item_page = ({selected_branche,counter_name,branche_num}) => {
            data.value.selected_branche = selected_branche
            data.value.counter_name = counter_name
            data.value.branche_num = branche_num
            state.value = 'ItemsPage'
        }

        const handle_submit = async({weight,selected_item}) => {
            if(!data.value.date.includes('.') || data.value.date.split('.')[2].length==4){
                data.value.selected_item = selected_item
                data.value.selected_item.weight = weight
                is_pending.value = true
                await save_count_stock_in_db(data.value)
                is_pending.value = false
                alert('success','הצלחה','הספירה נשמרה בהצלחה')
                .then(()=>{
                    reset_item_page.value = true
                })
            }else{
                alert('error','שגיאה','קיימת בעיה בשעון של המחשב שלך!')
            }
        }
        const handle_submit_update = async(item) => {
            is_pending.value = true
            await update_count_stock_in_db(data.value,item)
            is_pending.value = false
            alert('success','הצלחה','הספירה עודכנה בהצלחה')
            .then(()=>{
                reset_item_page.value = true
            })
        }

        const handle_submit_delete = async(item) => {
            is_pending.value = true
            await delete_count_stock_in_db(data.value,item)
            is_pending.value = false
            alert('success','הצלחה','הספירה נמחקה בהצלחה')
            .then(()=>{
                reset_item_page.value = true
            })
        }

        const init = async() => {
            branches.value = await get_branches_from_db()
            items.value = await get_data_from_db()
        }

        
       
        init()

        onBeforeUnmount(()=>{
            counted_selected.value = []
        })
        return{
            data,
            branches,
            state,
            items,
            handle_go_to_item_page,
            handle_submit,
            handle_submit_update,
            reset_item_page,
            handle_back_to_start,
            after_exit,
            handle_submit_delete
        }
    }
}
</script>

<style scoped>
    .stock-client-main{
        width: 100%;
        height: 100%;
        padding: 5px;
        color: #fff;
    }
    .stock-client-main-wrapper{
        width: 100%;
        max-width: 1200px;
        height: 100%;
        margin: 0 auto;
    }
    .stock-client-main-wrapper-header{
        width: 100%;
        height: 50px;
        background: var(--secondary);
        border-radius: 10px;
        display: flex;
        justify-content: center;
        align-items: center;
        text-align: center;
    }
    .stock-client-main-wrapper-content{
        width: 100%;
        height: calc(100% - 50px);
    }
</style>